import '../i18n';

import { PageLoading } from '@amzn/sitc-frontend/components';
import { AuthSession, AuthSessionProvider } from '@amzn/sitc-frontend/contexts';
import { Radio } from '@amzn/sitc-frontend/utils/radio';
import { backlotLightThemeExperimental } from '@amzn/sitc-frontend-theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import i18n from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthorizationProvider } from '../context/Authorization';
import { useAppTitle } from '../hooks/app-title';
import { NotFound } from '../pages/error/NotFound';
import { ResponseHistoryProvider } from '../visualizer/context/ResponseContext';
import { VisualizerPage } from '../visualizer/pages/VisualizerPage';

interface Props {
  appId: string;
  authSession: AuthSession;
  basePath: string;
  stage: string;
  radio: Radio;
}

const Index = React.lazy(() => import('../pages/Index'));
const ApolloSandboxExplorerPage = React.lazy(() => import('../pages/ApolloSandboxExplorerPage'));
const GraphiQlExplorerPage = React.lazy(() => import('../pages/GraphiQlExplorerPage'));
const MesaPage = React.lazy(() => import('../pages/MesaPage'));

const StudiosDataMeshExplorer: React.FC<Props> = (props) => {
  const { authSession, basePath, stage, radio } = props;

  useAppTitle(radio.getChannel('app'));

  return (
    <ThemeProvider theme={backlotLightThemeExperimental}>
      <CssBaseline />
      <I18nextProvider i18n={i18n}>
        <BrowserRouter basename={basePath}>
          <AuthSessionProvider authSession={authSession}>
            <AuthorizationProvider authSession={authSession} stage={stage}>
              <ResponseHistoryProvider>
                <Suspense fallback={<PageLoading />}>
                  <section style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <Routes>
                      <Route path="/" element={<Index {...props} />} />
                      <Route path="/apollo-sandbox" element={<ApolloSandboxExplorerPage {...props} />} />
                      <Route path="/graphiql" element={<GraphiQlExplorerPage {...props} />} />
                      <Route path="/visualizer" element={<VisualizerPage />} />
                      <Route path="/query-assistant" element={<MesaPage {...props} />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </section>
                </Suspense>
              </ResponseHistoryProvider>
            </AuthorizationProvider>
          </AuthSessionProvider>
        </BrowserRouter>
      </I18nextProvider>
    </ThemeProvider>
  );
};

export default StudiosDataMeshExplorer;
