import React, { createContext, MutableRefObject, useContext, useMemo, useRef } from 'react';

import { DataMeshExplorerResponse } from '../util/helper-types';

interface ResHistoryProviderProps {
  children: React.ReactNode;
}

interface ResponseContextValue {
  responseRef: MutableRefObject<DataMeshExplorerResponse | null>;
}

const ResponseContext = createContext<ResponseContextValue | undefined>(undefined);

export const ResponseHistoryProvider: React.FC<ResHistoryProviderProps> = ({ children }) => {
  const responseRef = useRef(null);
  const contextValue = useMemo(() => ({ responseRef }), []);

  return <ResponseContext.Provider value={contextValue}>{children}</ResponseContext.Provider>;
};

export const useResponseContext = (): ResponseContextValue => {
  const responseContext = useContext(ResponseContext);
  if (responseContext === undefined) {
    throw new Error('useResponseContext must be inside a ResponseHistoryProvider');
  }
  return responseContext;
};
