import { EdgeDefinition, ElementsDefinition, NodeDefinition } from 'cytoscape';

import { JsonObject } from '../util/helper-types';

export interface ImportDataFormat {
  vertices: NodeDefinition[];
  edges: EdgeDefinition[];
}
export interface ImportDataMapping {
  nodeId: string | number;
  edgeId: string | number;
  nodeLabel: string | number;
  edgeLabel: string | number;
  edgeSource: string | number;
  edgeTarget: string | number;
}

function mapNodes(nodes: NodeDefinition[], mappingConfig: ImportDataMapping) {
  return nodes.map((node) => ({
    id: (node as JsonObject)[mappingConfig.nodeId],
    data: {
      label: node.data.schemaReference.schemaName || '',
      ...node,
    },
  }));
}

function areFluentEdges(edges: EdgeDefinition[]): boolean {
  return edges[0].data !== undefined;
}

function mapEdges(edges: EdgeDefinition[], mappingConfig: ImportDataMapping) {
  let mappedEdges: EdgeDefinition[];
  if (areFluentEdges(edges)) {
    mappedEdges = edges.map((edge) => ({
      id: (edge as unknown as JsonObject)[mappingConfig.edgeId],
      data: {
        label: edge.data[mappingConfig.edgeLabel] || '',
        source: edge.data[mappingConfig.edgeSource],
        target: edge.data[mappingConfig.edgeTarget],
        ...edge,
      },
    }));
  } else {
    mappedEdges = edges.map((edge) => ({
      id: (edge as unknown as JsonObject)[mappingConfig.edgeId],
      data: {
        label: (edge as unknown as JsonObject)[mappingConfig.edgeLabel] || '',
        source: (edge as unknown as JsonObject)[mappingConfig.edgeSource] as string,
        target: (edge as unknown as JsonObject)[mappingConfig.edgeTarget] as string,
        ...edge,
      },
    }));
  }

  return mappedEdges;
}

export const mapImportedData = (data: ImportDataFormat, mappingConfig: ImportDataMapping): ElementsDefinition => {
  const { vertices, edges } = data;
  const mappedNodes = mapNodes(vertices, mappingConfig);
  const mappedEdges = mapEdges(edges, mappingConfig);
  return { nodes: mappedNodes, edges: mappedEdges };
};
