import { Box } from '@mui/material';
import { ElementsDefinition } from 'cytoscape';
import React, { useEffect, useState } from 'react';

import { ImportModal } from './components/ImportModal';
import Navbar from './components/Navbar';
import { useResponseContext } from './context/ResponseContext';
import { Graph } from './graph/Graph';
import { handleResponse } from './import/response-handler';
import { DataMeshExplorerResponse } from './util/helper-types';

export const Visualizer = () => {
  const { responseRef } = useResponseContext();
  const initElement: ElementsDefinition = { nodes: [], edges: [] };
  const [elements, setElements] = useState<ElementsDefinition>(initElement);
  const [isImportModalOpen, setImportModal] = useState(false);
  const navbarProps = { setImportModal };

  useEffect(() => {
    if (responseRef.current) {
      handleResponse(responseRef.current, setElements);
    } else {
      setElements({ edges: [], nodes: [] });
    }
  }, []);

  const saveImportData = (data: string) => {
    handleResponse(JSON.parse(data) as DataMeshExplorerResponse, setElements);
  };

  return (
    <Box>
      <Navbar {...navbarProps} />
      <ImportModal isImportModalOpen={isImportModalOpen} setImportModal={setImportModal} setImportData={saveImportData} />
      <Graph elements={elements} />
    </Box>
  );
};
