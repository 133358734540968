import { AuthSession } from '@amzn/sitc-frontend/contexts';
import { Action } from '@amzn/studios-data-mesh-explorer-auth/constants';
import { DataMeshExplorerUserAuth } from '@amzn/studios-data-mesh-explorer-auth/user-auth';
import React, { useContext, useMemo } from 'react';

interface AuthProviderProps {
  stage: string;
  authSession: AuthSession;
  children: React.ReactNode;
}

export const AuthContext = React.createContext<{
  userAuth?: DataMeshExplorerUserAuth;
  authSession?: AuthSession;
}>({
  userAuth: undefined,
  authSession: undefined,
});

export const AuthorizationProvider: React.FC<AuthProviderProps> = ({ authSession, children, stage }) => {
  const contextValue = useMemo(() => {
    const userAuth = new DataMeshExplorerUserAuth({ customGroups: authSession?.userGroups || [] }, { stage });

    return { userAuth, authSession };
  }, [stage, authSession]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const usePermission = (action: Action): boolean => {
  const { userAuth } = useContext(AuthContext);

  if (!userAuth) {
    return false;
  }

  return userAuth.evaluatePermission(action);
};
