import { Channel } from '@amzn/sitc-frontend/utils/radio';
import { useTranslation } from 'react-i18next';

export const useAppTitle = (appChannel?: Channel): void => {
  const { t } = useTranslation();

  const productName = t('app.product_name');

  if (appChannel) {
    appChannel.publish('page-metadata-update', {
      payload: {
        productName,
      },
    });
  } else {
    document.title = productName;
  }
};
