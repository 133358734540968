import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Navbar = (props: { setImportModal: (isImportModalOpen: boolean) => void }) => {
  const { setImportModal } = props;
  const showImportModal = () => setImportModal(true);
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          display: 'flex',
          height: '3.75rem',
          backgroundColor: (theme) => theme.palette.background.paper,
        }}>
        <Toolbar sx={{ paddingBottom: '1.25rem' }}>
          <Typography mr="0.375rem" variant="h5" color="secondary">
            {t('visualizer.navbar.title')}
          </Typography>
          <Typography mr="0.625rem" variant="caption" color="secondary">
            {t('visualizer.navbar.version')}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Button onClick={showImportModal} color="secondary" variant="outlined" size="small" sx={{ ml: '0.625rem' }}>
            {t('visualizer.navbar.import')}
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
