import { MaybeNull } from '@amzn/sitc-frontend-types/helper';
import { ElementsDefinition } from 'cytoscape';

import { DataMeshExplorerResponse, QueryResponse } from '../util/helper-types';
import { handleFluentResponse } from './fluent-response-handler';
import { handleNonFluentResponse } from './non-fluent-response-handler';

const nonFluentQueries = ['getDocuments', 'searchDocuments'];
const fluentQueries = ['traversalFromDocumentRoots', 'traversalFromVertexId', 'traversalSearchFromDocumentRoots'];
const jsonConstructor = {}.constructor;

// TODO: https://originalsaccess.atlassian.net/browse/CLOUD-26118
export const getDomainData = (response: DataMeshExplorerResponse): QueryResponse => {
  const { title, directory } = response.data;

  if (directory) {
    return directory;
  }

  if (title) {
    return title;
  }

  return {};
};

const isFluentQuery = (query: string): boolean => fluentQueries.includes(query);

const isValidQuery = (arg: string): boolean => nonFluentQueries.includes(arg) || fluentQueries.includes(arg);

const isJsonObject = (object: unknown): boolean => {
  const objectConstructor = object?.constructor;
  return objectConstructor === jsonConstructor;
};

export const identifyQuery = (json: Record<string, unknown>): MaybeNull<string> => {
  const entries = Object.entries(json);
  for (let index = 0; index < entries.length; index += 1) {
    const [key, value] = entries[index];
    if (value && (isJsonObject(value) || Array.isArray(value))) {
      if (isValidQuery(key)) {
        return key;
      }
      const result = identifyQuery(value as Record<string, unknown>);
      if (result !== null) {
        return result;
      }
    }
  }
  return null;
};

export const handleResponse = (
  response: DataMeshExplorerResponse,
  setGraphElements: React.Dispatch<React.SetStateAction<ElementsDefinition>>
): void => {
  const query = identifyQuery(response as unknown as Record<string, unknown>);

  if (!query) {
    return;
  }

  const domainData = getDomainData(response);
  if (isFluentQuery(query)) {
    handleFluentResponse(domainData, query, setGraphElements);
  } else {
    handleNonFluentResponse(domainData, query, setGraphElements);
  }
};
