import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

interface Action {
  action: () => void;
  icon: React.ReactNode;
  name: string;
}

export const VisualizerBasePage: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const actions: Action[] = [
    { name: t('navigation.home'), icon: <HomeIcon color="secondary" />, action: (): void => navigate('/') },
    { name: t('navigation.return'), icon: <ArrowBackIcon color="secondary" />, action: (): void => navigate('/apollo-sandbox') },
  ];

  return (
    <Box sx={{ padding: 0, height: '100vh' }}>
      {children}
      <SpeedDial ariaLabel="Navigation" sx={{ position: 'absolute', bottom: '2rem', right: '2rem' }} icon={<SpeedDialIcon />}>
        {actions.map((action: Action) => (
          <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={action.action} />
        ))}
      </SpeedDial>
    </Box>
  );
};
