import ReportIcon from '@mui/icons-material/Report';
import { Box, Button, Grid, Paper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const VisualizerErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100vh', minWidth: '100vw' }}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& > :not(style)': {
              m: 5,
              width: 300,
              height: 128,
            },
          }}>
          <Paper
            elevation={16}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <ReportIcon sx={{ color: 'red', marginRight: '10px' }} />
            <span style={{ color: 'red' }}>{t('visualizer.error')}</span>
          </Paper>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Button variant="contained" color="error" onClick={() => navigate('/apollo-sandbox')}>
            {t('visualizer.error.back')}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};
