import { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import { PropertyValue } from 'csstype';
import cytoscape, { Core, Layouts, NodeSingular } from 'cytoscape';
import cola from 'cytoscape-cola';
import popper from 'cytoscape-popper';
import React, { MutableRefObject } from 'react';
import stringToColor from 'string-to-color';

import { NodePopperMap, PopperMapJson } from '../util/helper-types';

interface DestroyPopperProps {
  nodePopperMap: NodePopperMap;
  nodeId: string;
  popperCountRef: MutableRefObject<number>;
  setPopperCount: React.Dispatch<React.SetStateAction<number>>;
}

interface CreatePopperProps {
  node: NodeSingular;
  nodeId: string;
  graph: MutableRefObject<MaybeUndefined<Core>>;
  nodePopperMap: NodePopperMap;
  popperCountRef: MutableRefObject<number>;
  setPopperCount: React.Dispatch<React.SetStateAction<number>>;
}

export interface InitializeGraphProps {
  graph: MutableRefObject<MaybeUndefined<Core>>;
  container: MutableRefObject<HTMLDivElement>;
  elements: cytoscape.ElementsDefinition;
  layout: MutableRefObject<MaybeUndefined<Layouts>>;
  nodePopperMapRef: MutableRefObject<NodePopperMap>;
  popperCountRef: MutableRefObject<number>;
  setPopperCount: React.Dispatch<React.SetStateAction<number>>;
}

/* eslint-disable no-param-reassign */
const destroyPopper = ({ nodePopperMap, nodeId, popperCountRef, setPopperCount }: DestroyPopperProps) => {
  const { popperInstance, popperDiv } = nodePopperMap.get(nodeId) as PopperMapJson;
  document.body.removeChild(popperDiv);
  popperInstance.destroy();
  nodePopperMap.delete(nodeId);
  popperCountRef.current -= 1;
  setPopperCount(popperCountRef.current);
};

const createPopper = ({ node, nodeId, graph, nodePopperMap, popperCountRef, setPopperCount }: CreatePopperProps) => {
  const nodeData = node.data('data');
  const popperDiv = document.createElement('div');
  const popperInstance = node.popper({
    content: () => {
      document.body.appendChild(popperDiv);
      return popperDiv;
    },
  });

  const update = () => {
    popperInstance.update().catch(() => {
      throw new Error('error updating popper instance');
    });
  };

  node.on('position', update);
  graph.current?.on('pan zoom resize', update);

  nodePopperMap.set(nodeId, { popperInstance, popperDiv, nodeData, nodeId, nodeLabel: node.data('label') as string });
  popperCountRef.current += 1;
  setPopperCount(popperCountRef.current);
};

export const initializeGraph = ({
  graph,
  container,
  elements,
  layout,
  nodePopperMapRef,
  popperCountRef,
  setPopperCount,
}: InitializeGraphProps) => {
  if (!graph) {
    return;
  }

  cytoscape.use(cola as cytoscape.Ext);
  cytoscape.use(popper as cytoscape.Ext);
  graph.current = cytoscape({
    container: container.current,
    elements,
    style: [
      {
        selector: 'node',
        style: {
          label: (ele: PropertyValue<NodeSingular>) => ele.data('label') as string,
          backgroundColor: (ele: PropertyValue<NodeSingular>) => stringToColor(ele.data('label')),
        },
      },
      {
        selector: 'edge',
        style: {
          label: (ele: PropertyValue<NodeSingular>) => ele.data('label') as string,
        },
      },
    ],
  });
  layout.current = graph.current.layout({
    name: 'cola',
  });

  graph.current.on('dblclick', 'node', (event) => {
    const nodePopperMap = nodePopperMapRef.current;
    const node: NodeSingular = event.target;
    const nodeId = node.id();

    if (nodePopperMap.has(nodeId)) {
      destroyPopper({ nodePopperMap, nodeId, popperCountRef, setPopperCount });
    } else {
      createPopper({ node, nodeId, graph, nodePopperMap, popperCountRef, setPopperCount });
    }
  });
};

export const addGraphElements = (
  graph: MutableRefObject<MaybeUndefined<Core>>,
  layout: MutableRefObject<MaybeUndefined<Layouts>>,
  elements: cytoscape.ElementsDefinition
) => {
  if (!graph) {
    return;
  }

  graph.current?.add(elements);
  layout.current = graph.current?.layout({
    name: 'cola',
  });
  layout.current?.run();
};
/* eslint-enable no-param-reassign */
