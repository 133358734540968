import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ImportModal = (props: {
  isImportModalOpen: boolean;
  setImportModal: (isImportModalOpen: boolean) => void;
  setImportData: (data: string) => void;
}) => {
  const { t } = useTranslation();
  const { isImportModalOpen, setImportModal, setImportData } = props;
  const [jsonTxt, setJsonTxt] = useState('');
  const onTxtChange = (event: { target: { value: React.SetStateAction<string> } }) => setJsonTxt(event.target.value);
  const handleClose = () => setImportModal(false);
  const saveAndClose = () => {
    setImportData(jsonTxt);
    setJsonTxt('');
    setImportModal(false);
  };

  return (
    <Modal open={isImportModalOpen}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: (theme) => theme.palette.background.paper,
          p: 4,
        }}>
        <Typography variant="h4" marginBottom="0.375rem">
          {t('visualizer.modal.title')}
        </Typography>
        <TextField multiline minRows={20} maxRows={20} onChange={onTxtChange} />
        <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '1px' }}>
          <Grid item>
            <Button size="large" color="error" onClick={handleClose}>
              {t('visualizer.modal.close')}
            </Button>
          </Grid>
          <Grid item>
            <Button size="large" color="secondary" variant="outlined" onClick={saveAndClose}>
              {t('visualizer.modal.save')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
