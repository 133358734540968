import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';
import ReactJson from 'react-json-view';

import { JsonObject } from '../util/helper-types';

export const VertexDetailsWindow = (props: { nodeData: JsonObject; nodeId: string; nodeLabel: string }) => {
  const { nodeData, nodeId, nodeLabel } = props;
  delete nodeData.schemaReference;
  nodeData.id = nodeId;
  return (
    <Container
      sx={{
        overflow: 'scroll',
        width: '25rem',
        maxHeight: '32.5rem',
        backgroundColor: (theme) => theme.palette.background.paper,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: (theme) => theme.palette.secondary.main,
      }}>
      <Box padding="0.625rem">
        <ReactJson src={nodeData} collapsed={1} enableClipboard={false} indentWidth={2} name={nodeLabel} displayDataTypes={false} />
      </Box>
    </Container>
  );
};
