import cytoscape from 'cytoscape';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { VertexDetailsWindow } from '../components/VertexDetailsWindow';
import { NodePopperMap, PopperMapJson } from '../util/helper-types';
import { addGraphElements, initializeGraph, InitializeGraphProps } from './graph-utility';

export const createPoppers = (popperMap: NodePopperMap) => {
  const portals: React.ReactPortal[] = [];
  const popperData = Array.from(popperMap.values());
  popperData.forEach((element) => {
    const { popperDiv, nodeData, nodeId, nodeLabel } = element;
    const props = { nodeData, nodeId, nodeLabel };
    portals.push(createPortal(<VertexDetailsWindow {...props} />, popperDiv));
  });
  return portals;
};

export const Graph = (props: { elements: cytoscape.ElementsDefinition }) => {
  const { elements } = props;
  const container = useRef<HTMLDivElement>(document.createElement('div'));
  const graph = useRef<cytoscape.Core>();
  const layout = useRef<cytoscape.Layouts>();

  const nodePopperMapRef = useRef(new Map<string, PopperMapJson>());
  const popperCountRef = useRef(0);
  const [popperCount, setPopperCount] = useState(popperCountRef.current);

  const initializeGraphProps: InitializeGraphProps = {
    graph,
    container,
    elements,
    layout,
    nodePopperMapRef,
    popperCountRef,
    setPopperCount,
  };

  useEffect(() => {
    if (graph.current) {
      addGraphElements(graph, layout, elements);
    } else {
      initializeGraph(initializeGraphProps);
    }
  }, [elements]);

  return (
    <main>
      {popperCount !== 0 && createPoppers(nodePopperMapRef.current)}
      <div
        ref={container}
        style={{
          height: '100vh',
          width: '100vw',
        }}
      />
    </main>
  );
};
