import { NotFoundError } from '@amzn/sitc-frontend/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onAction = () => {
    navigate('/');
  };

  return <NotFoundError actionLabel={t('app.error.not_found.actionLabel')} onAction={onAction} />;
};
