import React from 'react';

import { Visualizer } from '../Visualizer';
import { VisualizerBasePage } from './VisualizerBasePage';
import { VisualizerErrorBoundary } from './VisualizerErrorBoundary';

export const VisualizerPage = () => (
  <VisualizerErrorBoundary>
    <VisualizerBasePage>
      <Visualizer />
    </VisualizerBasePage>
  </VisualizerErrorBoundary>
);
