import { en as sitcEn } from '@amzn/sitc-frontend/lang';

export const en = {
  ...sitcEn,
  'app.product_name': 'Data Mesh Explorer',
  'app.header.product_identifier': 'Data Mesh Explorer',
  'app.error.not_found.actionLabel': 'Back to Homepage',
  'app.error.access_denied.actionLabel': 'Ask for Access',
  'app.error.schema.action_label': 'Retry',
  'app.loading.schema': 'Loading Schema...',
  'home.card.apollo_sandbox.title': 'ISD-G (Apollo Sandbox)',
  'home.card.apollo_sandbox.description': 'Explore Domain Authorities through ISD-G using the Apollo Sandbox.',
  'home.card.graphiql.title': 'ISD-G (GraphiQL)',
  'home.card.graphiql.description': 'Explore Domain Authorities through ISD-G using the GraphiQL IDE.',
  'home.card.mesa.title': 'Data Mesh Query Assistant',
  'home.card.mesa.description': 'Get help constructing queries.',
  'mesa.page.desc': 'All chats will be logged and may be used to improve accuracy.',
  'navigation.home': 'Home',
  'navigation.visualize': 'Visualize',
  'navigation.return': 'Return',
  'visualizer.navbar.title': 'Graph Visualizer',
  'visualizer.navbar.version': 'Alpha',
  'visualizer.navbar.import': 'Import',
  'visualizer.modal.title': 'Import',
  'visualizer.modal.close': 'Close',
  'visualizer.modal.save': 'Save',
  'visualizer.error': 'Error generating graph.',
  'visualizer.error.back': 'Back To Explorer',
};
