import { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import { Document } from '@amzn/studios-domain-authority-java-script-clients/types/isdg';
import { ElementsDefinition } from 'cytoscape';

import { QueryResponse } from '../util/helper-types';
import { ImportDataFormat, mapImportedData } from './mapper';

export const getQueryData = (response: QueryResponse, query: string): MaybeUndefined<Document[]> => {
  switch (query) {
    case 'getDocuments':
      return response?.getDocuments?.documents as Document[];
    case 'searchDocuments':
      return response?.searchDocuments?.matchedDocuments as Document[];
    default:
      return undefined;
  }
};

export const handleNonFluentResponse = (
  response: QueryResponse,
  query: string,
  setGraphElements: React.Dispatch<React.SetStateAction<ElementsDefinition>>
): void => {
  const importDataMapping = {
    nodeId: 'id',
    nodeLabel: 'schemaName',
    edgeId: 'id',
    edgeLabel: 'edgeType',
    edgeSource: 'sourceVertexId',
    edgeTarget: 'targetVertexId',
  };
  const documents = getQueryData(response, query);
  documents?.forEach((document) => {
    setGraphElements(mapImportedData(document?.documentGraph as unknown as ImportDataFormat, importDataMapping));
  });
};
