import { StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import { ErrorBoundary } from './components/ErrorBoundary';
import StudiosDataMeshExplorer from './components/StudiosDataMeshExplorer';

const configuration = {};
const rootComponent = process.env.STANDALONE
  ? () => <StandaloneConfiguration RootComponent={StudiosDataMeshExplorer} configuration={configuration} />
  : StudiosDataMeshExplorer;

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent,
  errorBoundary: (error: Error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
