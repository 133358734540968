import { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import { SchemaedData } from '@amzn/studios-domain-authority-java-script-clients/types/isdg';
import { EdgeDefinition, ElementsDefinition, NodeDefinition } from 'cytoscape';

import { JsonObject, QueryResponse } from '../util/helper-types';
import { mapImportedData } from './mapper';

interface ParseNodeArguments {
  node: JsonObject;
  prevKey: string;
  vertices: NodeDefinition[];
  edges: EdgeDefinition[];
}

const isEdgeIdentifier = (arg: string): boolean => arg.startsWith('out_') || arg.startsWith('in_');

/* eslint no-underscore-dangle: [1, { "allow": ["_id"] }] */
export const parseNode = ({ node, prevKey, vertices, edges }: ParseNodeArguments): void => {
  const vertex: JsonObject = { id: node._id, data: { schemaReference: { schemaName: '' } } };

  Object.entries(node).forEach(([nodeKey, nodeValue]) => {
    if (!isEdgeIdentifier(nodeKey)) {
      if (vertex.data && nodeKey !== '_id') {
        vertex.data[nodeKey] = nodeValue;
      }
    } else {
      const nodeToProcess = Array.isArray(nodeValue) ? (nodeValue as Array<JsonObject>) : [nodeValue];
      nodeToProcess.forEach((element) => {
        parseNode({ node: element as JsonObject, prevKey: nodeKey, vertices, edges });
        edges.push({ data: { source: node._id as string, target: (element as JsonObject)._id as string } });
      });
    }
    if (vertex.data && (isEdgeIdentifier(prevKey) || prevKey === 'root')) {
      vertex.data.schemaReference = { schemaName: prevKey };
    }
  });

  vertices.push(vertex as unknown as NodeDefinition);
};

export const getQueryData = (response: QueryResponse, query: string): MaybeUndefined<SchemaedData | SchemaedData[]> => {
  switch (query) {
    case 'traversalFromDocumentRoots':
      return response?.traversalFromDocumentRoots as SchemaedData[];
    case 'traversalFromVertexId':
      return response?.traversalFromVertexId as SchemaedData;
    case 'traversalSearchFromDocumentRoots':
      return response?.traversalSearchFromDocumentRoots?.schemaedData as SchemaedData[];
    default:
      return undefined;
  }
};

export const handleFluentResponse = (
  fluentResponse: QueryResponse,
  query: string,
  setGraphElements: React.Dispatch<React.SetStateAction<ElementsDefinition>>
): void => {
  const importDataMapping = {
    nodeId: 'id',
    nodeLabel: 0,
    edgeId: 0,
    edgeLabel: 0,
    edgeSource: 'source',
    edgeTarget: 'target',
  };
  const edges: EdgeDefinition[] = [];
  const vertices: NodeDefinition[] = [];
  const queryData = getQueryData(fluentResponse, query);

  if (!queryData) {
    return;
  }

  const dataToProcess = Array.isArray(queryData) ? queryData : [queryData];
  dataToProcess.forEach((data) => {
    parseNode({ node: data as JsonObject, prevKey: 'root', vertices, edges });
    setGraphElements(mapImportedData({ edges, vertices }, importDataMapping));
  });
};
